import {
  Message,
} from '@/types/internal'
import {CalendarEntry, Employee, Project} from '@/types/descript_kundenverwaltung_rest'

/**
 * Current State of the Vue App
 */
export interface State {
  immediateAlerts: Array<Message>,
  pendingAlerts: Array<Message>,
  employees: Array<Employee>,
  calendarEntries: Array<CalendarEntry>,
  projects: Array<Project>
}

export function getEmptyState (): State {
  return {
    immediateAlerts: [],
    pendingAlerts: [],
    employees: [],
    calendarEntries: [],
    projects: []
  }
}
