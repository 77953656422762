import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import SchedulingBoardView from '../views/SchedulingBoardView.vue'
import UserLogin from '@/views/auth/UserLogin.vue'
import { isAuthenticated } from '@/utils'
import { i18n } from '@/i18n'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: () => {
      if (isAuthenticated()) {
        return '/board'
      }

      return '/auth'
    }
  },
  {
    path: '/auth',
    name: 'UserLogin',
    component: UserLogin,
    meta: {
      title: 'meta.pageTitles.user_login'
    }
  },
  {
    path: '/board',
    name: 'SchedulingBoardView',
    component: SchedulingBoardView,
    meta: {
      title: 'meta.pageTitles.scheduling_board'
    }
  }
]

const pathsWhichNeedNoAuthentication = [
  'Home',
  'UserLogin',
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let name = ''
  if (to.name) {
    name = String(to.name)
  }
  if (name === 'UserLogin' && isAuthenticated()) {
    next('/board')
  } else if (pathsWhichNeedNoAuthentication.includes(name)) {
    next()
  } else {
    if (isAuthenticated()) {
      next()
    } else {
      next('/auth')
    }
  }
})


export default router
