<template>
  <div class="modal fade" tabindex="-1" :id="id">
    <div :class="`modal-dialog modal-dialog-centered ${sizeClass}`">
      <div class="modal-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Modal as BootstrapModal } from 'bootstrap'

export default defineComponent({
  // eslint-disable-next-line
  name: 'Modal',
  props: {
    id: {
      type: String,
      required: true
    },
    sizeClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      modalInstance: null as BootstrapModal | null
    }
  },
  methods: {
    show () {
      if (this.modalInstance) {
        this.modalInstance.show()
      }
    },
    hide () {
      if (this.modalInstance) {
        this.modalInstance.hide()
      }
    },
    dispose () {
      if (this.modalInstance) {
        this.modalInstance.dispose()
      }
    }
  },
  mounted: function () {
    this.modalInstance = new BootstrapModal(this.$el)
  },
  unmounted: function () {
    this.dispose()
  }
})
</script>

<style scoped></style>
