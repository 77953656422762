import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store } from './store'
import { i18n } from  '@/i18n'
import '@/assets/scss/style.scss'
import * as Sentry from '@sentry/browser'
import { isProduction, sentryConfiguration } from '@/config/config'
import { captureException } from '@/utils'
import dayjs from "dayjs"
import * as localizedFormat from "dayjs/plugin/localizedFormat"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
dayjs.extend(localizedFormat)
import "dayjs/locale/de"
dayjs.locale('de')
import ganttastic from "./ganttastic/vue-ganttastic"
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue'

Sentry.init(sentryConfiguration)
window.addEventListener('error', captureException)

window.addEventListener('unhandledrejection', captureException)
const app = createApp(App).use(store).use(router).use(i18n).use(BootstrapIconsPlugin).use(ganttastic)

app.config.errorHandler = captureException

app.mount('#app')
