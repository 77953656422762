export interface Contact {
  first_name: string,
  last_name: string
}

export interface EmployeeFromRest {
  id: number
  mandatory_timer_descriptions: boolean
  contact: Contact
}

export class Employee {
  id: number
  mandatory_timer_descriptions: boolean
  contact: Contact

  constructor (restEmployee?: EmployeeFromRest) {
    if (!restEmployee){
      restEmployee = {
        id: 0,
        mandatory_timer_descriptions: false,
        contact: {
          first_name: '',
          last_name: ''
        }
      }
    }
    this.id = restEmployee.id
    this.mandatory_timer_descriptions = restEmployee.mandatory_timer_descriptions
    this.contact = restEmployee.contact
  }
}

export enum CalendarEntryKind {
  holiday = 'holiday',
  sickness = 'sickness',
  sickness_without_certificate = 'sickness_without_certificate',
  sickness_child = 'sickness_child',
  workshop = 'workshop',
  student_working = 'student_working',
  homeoffice = 'homeoffice',
  freetime_compensation = 'freetime_compensation'
}

export interface CalendarEntryFromRest {
  id: number,
  caldav_uid: string,
  m365_uid: string,
  m365_uid_shared_calendar: string,
  start_date: string,
  end_date: string,
  kind: CalendarEntryKind,
  created: string,
  modified: string,
  approved: boolean,
  approved_datetime: string,
  note: string,
  employee: number,
  created_by: number,
  approved_by: number|null,
  project: number|null
}

export class CalendarEntry {
  id: number
  caldav_uid: string
  m365_uid: string
  m365_uid_shared_calendar: string
  start_date: string
  end_date: string
  kind: CalendarEntryKind
  created: string
  modified: string
  approved: boolean
  approved_datetime: string|null
  note: string
  employee: number
  created_by: number
  approved_by: number|null
  project: number|null

  constructor (restCalenderEntry?: CalendarEntryFromRest) {
    if (!restCalenderEntry) {
      restCalenderEntry = {
        id: 0,
        caldav_uid: '',
        m365_uid: '',
        m365_uid_shared_calendar: '',
        start_date: '',
        end_date: '',
        kind: CalendarEntryKind.student_working,
        created: '',
        modified: '',
        approved: false,
        approved_datetime: '',
        note: '',
        employee: 0,
        created_by: 0,
        approved_by: 0,
        project: 0
      }
    }
    this.id = restCalenderEntry.id
    this.caldav_uid = restCalenderEntry.caldav_uid
    this.m365_uid = restCalenderEntry.m365_uid
    this.m365_uid_shared_calendar = restCalenderEntry.m365_uid_shared_calendar
    this.start_date = restCalenderEntry.start_date
    this.end_date = restCalenderEntry.end_date
    this.kind = restCalenderEntry.kind
    this.created = restCalenderEntry.created
    this.modified = restCalenderEntry.modified
    this.approved = restCalenderEntry.approved
    this.approved_datetime = restCalenderEntry.approved_datetime
    this.note = restCalenderEntry.note
    this.employee = restCalenderEntry.employee
    this.created_by = restCalenderEntry.created_by
    this.approved_by = restCalenderEntry.approved_by
    this.project = restCalenderEntry.project
  }
}

export interface CalendarEntryPayload {
  id: number
  start_date?: string
  end_date?: string
  kind?: CalendarEntryKind
  employee?: number
  project?: number
}

export interface ProjectFromRest {
  id: number,
  customer: string,
  title: string,
  start_date: string | null,
  end_date: string | null
}

export class Project {
  id: number
  customer: string
  title: string
  start_date: string | null
  end_date: string | null
  fixed_date?: boolean  // this is a placeholder for now, this prevents the user from move the bar

  constructor (restProject?: ProjectFromRest) {
    if (!restProject) {
      restProject = {
        id: 0,
        customer: '',
        title: '',
        start_date: null,
        end_date: null
      }
    }
    this.id = restProject.id
    this.customer = restProject.customer
    this.title = restProject.title
    this.start_date = restProject.start_date
    this.end_date = restProject.end_date
  }

  customerAndTitle() {
    return this.customer + ': ' + this.title
  }

  projectInRange(date: Date|null) {
    if (date == null){
      return true
    }
    let gte_start = true
    let lte_end = true
    if (this.start_date) {
      gte_start = new Date(this.start_date) <= date
    }
    if (this.end_date) {
      lte_end = new Date(this.end_date) >= date
    }
    return lte_end && gte_start
  }
}

export interface ProjectPayload {
  id: number
  customer?: string
  title?: string
  start_date?: string
  end_date?: string
  fixed_date?: boolean
}
