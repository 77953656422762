<template>
  <button
    class="btn"
    v-bind:class=btnStyle
    v-bind:type=btnType
    v-bind:title=btnTitle
    :disabled="btnDisabled"
    :data-bs-toggle="tooltip ? 'tooltip' : null"
    :data-bs-placement="tooltip ? 'top' : null"
  >
    <template v-if="showIcon">
      <component :is="renderShowIcon(showIcon)" class="me-1"></component>
    </template>
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Tooltip } from 'bootstrap'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Button',
  props: {
    btnStyle: {
      type: String
    },
    btnType: {
      type: String
    },
    btnTitle: {
      type: String
    },
    btnDisabled: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: String
    },
    tooltip: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    renderShowIcon: function (icon: string): string {
      return 'b-icon-' + icon
    }
  },
  mounted: function () {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('button[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return Tooltip.getOrCreateInstance(tooltipTriggerEl)
    })
  }
})
</script>

<style scoped>
</style>
