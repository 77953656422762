<template>
  <div
    class="mb-3"
    :class="label ? 'form-floating': ''"
  >
    <input
      :type=type
      class="form-control"
      :class="elementStyle + (errorMessage ? ' is-invalid': '')"
      :name="name"
      :id=name
      :aria-describedby=helpId
      :placeholder="label ? label: placeholder"
      :value="inputValue"
      :disabled="disabled"
      @input="handleChange"
      @blur="handleBlurWrapper"
    >
    <label
      v-if="label"
      v-bind:for="name"
      v-bind:class="(required ? 'required ' : '') + `${labelStyle}`"
    >
      {{ label }}
    </label>
    <div
      class="invalid-feedback"
      v-show="!!errorMessage"
    >
      {{ errorMessage }}
    </div>
    <div
      v-if="helpText"
      v-bind:id=helpId
      class="form-text"
    >
      {{ helpText }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  props: {
    label: String,
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    value: String,
    elementStyle: {
      type: String,
      default: ''
    },
    labelStyle: {
      type: String,
      default: ''
    },
    helpText: String,
    placeholder: String,
    emitChange: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta
    } = useField(props.name, undefined, {
      initialValue: props.value
    })

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta
    }
  },
  methods: {
    handleBlurWrapper () {
      this.handleBlur()
      if (this.emitChange && this.meta.valid) {
        this.$emit('inputValue', this.inputValue)
      }
    }
  },
  computed: {
    helpId (): string {
      return this.name + 'Help'
    }
  },
  emits: ['inputValue']
})
</script>

<style scoped>
</style>
