<template>
  <footer
    class="container mt-4 mb-4 mt-lg-5"
    :class="noPrint ? 'd-print-none' : ''"
  >
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 d-flex">
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import LocaleChooser from '@/components/base/LocaleChooser.vue'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  props: {
    noPrint: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style scoped>
</style>
