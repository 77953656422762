import { State } from '@/store/state'
import { Filter, AlertType } from '@/types/internal'
import {
  CalendarEntry,
  CalendarEntryPayload,
  Employee,
  Project
} from '@/types/descript_kundenverwaltung_rest'

export const mutations = {
    /**
   * adds a Message Alert which will be displayed at the current view
   *
   * @example
   * this.$store.commit('addImmediateAlert', { msg: MESSAGE, type: AlertType.Danger })
   */
  addImmediateAlert (state: State, payload: { msg: string; type?: AlertType, autoHide?: boolean }): void {
    if (payload.type === undefined) {
      payload.type = AlertType.Info
    }
    if (payload.autoHide === undefined) {
      payload.autoHide = true
    }

    state.immediateAlerts.push({
      msg: payload.msg,
      type: payload.type,
      autoHide: payload.autoHide
    })
  },
  clearImmediateAlerts (state: State): void {
    // splice with this parameters is the performant way to empty an array
    state.immediateAlerts.splice(0, state.immediateAlerts.length)
  },
  clearAndDismissImmediateAlerts (state: State): void {
    // splice with this parameters is the performant way to empty an array
    state.immediateAlerts.splice(0, state.immediateAlerts.length)
  },
  /**
   * adds an Alert for the next visible view. The function will handle itself, that every unique alert message
   * will only be displayed once
   *
   * @example
   * this.$store.commit('addPendingAlert', { msg: MESSAGE, type: AlertType.Danger })
   */
  addPendingAlert (state: State, payload: { msg: string; type?: AlertType, autoHide?: boolean }): void {
    if (payload.type === undefined) {
      payload.type = AlertType.Info
    }
    if (payload.autoHide === undefined) {
      payload.autoHide = true
    }

    // A pending alert must only be added if there is not already an exact
    // same message in the list of pending alerts. Adding the same alert could
    // for example happen when multiple requests to the REST api are done and
    // they fail with a non authenticated error.

    let alreadyThere = false
    for (const pendingAlert of state.pendingAlerts) {
      if (pendingAlert.msg === payload.msg && pendingAlert.type === payload.type) {
        alreadyThere = true
      }
    }

    if (!alreadyThere) {
      state.pendingAlerts.push({
        msg: payload.msg,
        type: payload.type,
        autoHide: payload.autoHide
      })
    }
  },
  clearPendingAlerts (state: State): void {
    // splice with these parameters is the performant way to empty an array
    state.pendingAlerts.splice(0, state.pendingAlerts.length)
  },
  setEmployees (state: State, payload: Array<Employee>) {
    state.employees = payload
  },
  setCalendarEntries (state: State, payload: Array<CalendarEntry>) {
    state.calendarEntries = payload
  },
  updateCalenderEntry (state: State, payload: CalendarEntry) {
    const index = state.calendarEntries.findIndex((el) => {
      return payload.id == el.id
    })
    // Inject the updated instance at the same position in array.
    state.calendarEntries = [
      ...state.calendarEntries.slice(0, index),
      payload,
      ...state.calendarEntries.slice(index + 1)
    ]
  },
  setProjects (state: State, payload: Array<Project>) {
    state.projects = payload
  },
  updateProject (state: State, payload: Project) {
    const index = state.projects.findIndex((el) => {
      return payload.id == el.id
    })
    // Inject the updated instance at the same position in array.
    state.projects = [
      ...state.projects.slice(0, index),
      payload,
      ...state.projects.slice(index + 1)
    ]
  },
}
