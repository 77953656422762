import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center" }
const _hoisted_2 = { class: "flex-grow-1 text-start" }
const _hoisted_3 = { class: "flex-grow-1 text-center" }
const _hoisted_4 = { class: "flex-grow-1 text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alerts = _resolveComponent("Alerts")!
  const _component_g_gantt_row = _resolveComponent("g-gantt-row")!
  const _component_g_gantt_chart = _resolveComponent("g-gantt-chart")!
  const _component_ProjectModal = _resolveComponent("ProjectModal")!
  const _component_CalendarEntryModal = _resolveComponent("CalendarEntryModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Alerts),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", {
          class: "btn btn-secondary",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.minusOneWeek && _ctx.minusOneWeek(...args)))
        }, "Woche zurück")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          class: "btn btn-secondary",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.gotoCurrentWeek && _ctx.gotoCurrentWeek(...args)))
        }, "Aktuelle Woche")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", {
          class: "btn btn-secondary",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.plusOneWeek && _ctx.plusOneWeek(...args)))
        }, "Woche vor")
      ])
    ]),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_g_gantt_chart, {
          key: 0,
          "chart-start": _ctx.chartStart,
          "chart-end": _ctx.chartEnd,
          precision: "day",
          "row-height": 40,
          grid: "",
          width: "100%",
          "bar-start": "beginDate",
          "bar-end": "endDate",
          "date-format": _ctx.format,
          "label-column-title": " ",
          onClickBar: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onMouseupBar($event.bar, $event.e, $event.datetime))),
          onMousedownBar: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onMousedownBar($event.bar, $event.e, $event.datetime))),
          onDblclickBar: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onClickBar($event.bar, $event.e, $event.datetime))),
          onMouseenterBar: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onMouseenterBar($event.bar, $event.e))),
          onMouseleaveBar: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onMouseleaveBar($event.bar, $event.e))),
          onDragstartBar: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onDragstartBar($event.bar, $event.e))),
          onDragBar: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onDragBar($event.bar, $event.e))),
          onDragendBar: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onDragendBar($event.bar, $event.e, $event.movedBars))),
          onContextmenuBar: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onContextmenuBar($event.bar, $event.e, $event.datetime)))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.employees, (employee) => {
              return (_openBlock(), _createBlock(_component_g_gantt_row, {
                label: _ctx.getEmployeeName(employee),
                key: employee.id,
                bars: _ctx.bars[employee.id],
                "highlight-on-hover": "",
                onClickedTime: ($event: any) => (_ctx.newEntry($event.e, $event.value, employee))
              }, null, 8, ["label", "bars", "onClickedTime"]))
            }), 128)),
            _createVNode(_component_g_gantt_row, {
              label: _ctx.$t('text.open_construction_sites'),
              bars: _ctx.openProjectsBars,
              "highlight-on-hover": "",
              onClickedTime: _cache[3] || (_cache[3] = ($event: any) => (_ctx.newProject($event.e, $event.value)))
            }, null, 8, ["label", "bars"])
          ]),
          _: 1
        }, 8, ["chart-start", "chart-end", "date-format"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ProjectModal, {
      ref: "projectModal",
      "size-class": "modal-lg",
      employees: _ctx.employees,
      onProjectModalSaved: _ctx.updateBoard
    }, null, 8, ["employees", "onProjectModalSaved"]),
    _createVNode(_component_CalendarEntryModal, {
      ref: "calendarEntryModal",
      "size-class": "modal-lg",
      employees: _ctx.employees,
      onCalendarEntryModalSaved: _ctx.updateBoard
    }, null, 8, ["employees", "onCalendarEntryModalSaved"])
  ], 64))
}