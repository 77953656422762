import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "title", "disabled", "data-bs-toggle", "data-bs-placement"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn", _ctx.btnStyle]),
    type: _ctx.btnType,
    title: _ctx.btnTitle,
    disabled: _ctx.btnDisabled,
    "data-bs-toggle": _ctx.tooltip ? 'tooltip' : null,
    "data-bs-placement": _ctx.tooltip ? 'top' : null
  }, [
    (_ctx.showIcon)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.renderShowIcon(_ctx.showIcon)), {
          key: 0,
          class: "me-1"
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}