<template>
  <Modal data-bs-backdrop="static"
         data-bs-keyboard="false"
         id="calendar-entry-form-modal"
         ref="calendarEntryModal">
    <ModalHeader @modalClosed="hideModal">
      <ModalTitle :title="$t('form.calendar_entry_form')"></ModalTitle>
    </ModalHeader>
    <ModalBody class="pt-0">
      <Form @submit="storeCalendarEntry"
            :initial-values="instance"
            :validation-schema="schema"
            id="calendar-entry-form"
            ref="calendarEntryForm">
          <FormSelect :options="employeeChoices" name="employee"></FormSelect>
          <FormSelect :options="kindChoices" name="kind" v-on:change="kindChoiceChanged"></FormSelect>
          <div v-if="instance.kind == 'student_working'" >
            <FormSelect :options="projectChoices" name="project" v-on:change="projectChoiceChanged"></FormSelect>
            <FormInput v-if="instance.project == 'new_project'" name="project_name" :label="$t('form.project_title')" required></FormInput>
          </div>
          <FormInput type="date" name="start_date" :label="$t('form.start_date')" v-on:change="setDate" required></FormInput>
          <FormInput type="number" name="date_range" :label="$t('form.date_range')" v-on:change="dateRangeChanged"></FormInput>
          <FormInput type="date" name="end_date" :label="$t('form.end_date')" required></FormInput>
          <button class="btn btn-secondary" type="submit">
            {{ $t('text.save') }}
          </button>
          <span v-if="instance.id > 0" class="btn btn-secondary" v-on:click="removeEntry">
            {{ $t('text.remove') }}
          </span>
        </Form>
      </ModalBody>
      <ModalFooter>
      </ModalFooter>
  </Modal>
</template>

<script lang = 'ts' >
import { defineComponent, ref } from 'vue'
import ModalBody from '@/components/modal/ModalBody.vue'
import Modal from '@/components/modal/Modal.vue'
import ModalHeader from '@/components/modal/ModalHeader.vue'
import ModalTitle from '@/components/modal/ModalTitle.vue'
import ModalFooter from '@/components/modal/ModalFooter.vue'
import { Form } from 'vee-validate'
import { errorHelper } from '@/utils'
import * as yup from 'yup'
import FormInput from '@/components/forms/FormInput.vue'
import {
  CalendarEntryKind,
  CalendarEntryPayload,
  Employee,
  Project, ProjectPayload
} from '@/types/descript_kundenverwaltung_rest'
import FormSelect, { Option } from '@/components/forms/FormSelect.vue'
import { MAX_NUMBER_OF_DAYS } from '@/types/internal'
import dayjs from "dayjs"

const emptyEntry = {
  id: -1,
  employee: 0,
  kind: CalendarEntryKind.student_working,
  start_date: '' as string | Date,
  end_date: '' as string | Date,
  project: "new_project" as string | number | null
}

export default defineComponent({
  name: 'CalendarEntryModal',
  components: {
    FormSelect,
    Form,
    ModalBody,
    Modal,
    ModalHeader,
    ModalTitle,
    ModalFooter,
    FormInput,
  },
  computed: {
    employees: function (): Array<Employee> {
      return this.$store.state.employees
    },
    projects: function (): Array<Project> {
      return this.$store.state.projects
    },
    employeeChoices: function (): Array<Option> {
      const choices = []
      for (const employee of this.employees) {
        choices.push({id: employee.id, label: `${employee.contact.first_name} ${employee.contact.last_name}`})
      }
      return choices
    },
    projectChoices: function (): Array<Option>{
      const choices: Array<Option> = [this.newProjectChoice]
      let start: Date|null = null
      if (this.start_date != '') {
        start = new Date(this.start_date)
      }
      for (const project of this.projects) {
        if (project.projectInRange(start)) {
          choices.push({
            id: project.id,
            label: project.title
          })
        }
      }
      return choices
    }
  },
  data: function () {
    const schema = yup.object().shape({
      start_date: yup.date().required(this.$t('form_validation.required')),
      end_date: yup.date().required(this.$t('form_validation.required')),
      date_range: yup.number().max(MAX_NUMBER_OF_DAYS, this.$t('form_validation.too_large')),
      employee: yup.number().required(this.$t('form_validation.required')),
    })
    const kindChoices = [
      {id: CalendarEntryKind.holiday, label: this.$t('calendar_entry_type.holiday')},
      {id: CalendarEntryKind.sickness, label: this.$t('calendar_entry_type.sickness')},
      {id: CalendarEntryKind.sickness_without_certificate, label: this.$t('calendar_entry_type.sickness_without_certificate')},
      {id: CalendarEntryKind.sickness_child, label: this.$t('calendar_entry_type.sickness_child')},
      {id: CalendarEntryKind.workshop, label: this.$t('calendar_entry_type.workshop')},
      {id: CalendarEntryKind.student_working, label: this.$t('calendar_entry_type.student_working')},
      {id: CalendarEntryKind.homeoffice, label: this.$t('calendar_entry_type.homeoffice')},
      {id: CalendarEntryKind.freetime_compensation, label: this.$t('calendar_entry_type.freetime_compensation')}
    ]
    const newProjectChoice = {
      id: "new_project",
      label: this.$t('form.project_choices.new_project')
    }

    return {
      start_date: '',
      end_date: '',
      date_range: 0,
      schema: schema,
      kindChoices,
      currentKindChoice: CalendarEntryKind.student_working as string,
      currentProjectChoice: newProjectChoice.id,
      instance: emptyEntry,
      newProjectChoice,
    }
  },
  setup() {
    // refs to child components in a TypeScript compatible way,
    // see https://v3.vuejs.org/guide/typescript-support.html#typing-refs.
    const calendarEntryModal = ref<InstanceType<typeof Modal>>()
    return { calendarEntryModal }
  },
  methods: {
    show: function (employeeId?: number) {
      if (this.calendarEntryModal) {
        const calendarEntryRef = this.$refs.calendarEntryForm as typeof Form
        calendarEntryRef.setFieldValue('start_date', this.start_date.substring(0, 10))
        calendarEntryRef.setFieldValue('employee', employeeId)
        this.calendarEntryModal.show()
      }
    },
    hideModal () {
      this.resetForm()
      if (this.calendarEntryModal) {
        this.calendarEntryModal.hide()
      }
    },
    resetForm () {
      const calendarEntryRef = this.$refs.calendarEntryForm as typeof Form
      this.date_range = 0
      this.start_date = ''
      this.end_date = ''
      this.currentKindChoice = CalendarEntryKind.student_working
      this.instance = emptyEntry
      calendarEntryRef.resetForm()
    },
    storeCalendarEntry: async function (values: Record<string, any>) {
      let projectResponseData
      if ("project_name" in values && values["project"] == "new_project") {
        const startDate = dayjs(values["start_date"]).format()
        const endDate = dayjs(values["end_date"]).endOf("day").format()
        const data: ProjectPayload = {
          id: 0,
          start_date: startDate,
          end_date: endDate,
          title: values["project_name"]
        }
        projectResponseData = await this.$store.dispatch('createProject', data)
          .catch(errorHelper(this.$store))
        if (projectResponseData === undefined) {
          // no response means some error occurred, stopping here
          this.hideModal()
          return
        }
      }

      const data: CalendarEntryPayload = {
        id: this.instance.id,  // taking id from instance, because the initial form data is not updated when the instance updates from outside this component
        start_date: dayjs(values["start_date"]).format(),
        end_date: dayjs(values["end_date"]).endOf("day").format(),
        kind: values["kind"],
        employee: values["employee"],
        project: projectResponseData? projectResponseData.id : values["project"]
      }
      if (this.instance.id > 0) {
        await this.$store.dispatch('updateCalendarEntry', data).catch(errorHelper(this.$store))
      } else {
        await this.$store.dispatch('createCalendarEntry', data).catch(errorHelper(this.$store))
      }
      this.$emit('calendarEntryModalSaved')
      this.hideModal()
    },
    removeEntry: async function () {
      if (this.instance.id > 0) {
        await this.$store.dispatch('deleteCalendarEntry', this.instance.id).catch(errorHelper(this.$store))
        this.$emit('calendarEntryModalSaved')
        this.hideModal()
      }
    },
    setDate (event: Event) {
      this.start_date = (event.target as HTMLInputElement).value
    },
    dateRangeChanged (event: Event) {
      this.date_range = parseInt((event.target as HTMLInputElement).value)
    },
    calculateEnd: async function (dateRange: number, startDate: string) {
      const response_data = await this.$store.dispatch('getEndDate', {
        'start_date': new Date(startDate),
        'date_range': dateRange
      }).catch(errorHelper(this.$store))
      const calendarEntryRef = this.$refs.calendarEntryForm as typeof Form
      this.end_date = response_data['end_date']
      calendarEntryRef.setFieldValue('end_date', new Date((new Date(this.end_date).valueOf() - 1000)).toISOString().substring(0, 10))
    },
    kindChoiceChanged (event: Event) {
      this.instance.kind = (event.target as HTMLInputElement).value as CalendarEntryKind
    },
    projectChoiceChanged (event: Event) {
      this.instance.project = (event.target as HTMLInputElement).value
    }
  },
  watch: {
    date_range: function (newValue) {
      if (this.start_date !== '' && this.date_range < MAX_NUMBER_OF_DAYS) {
        this.calculateEnd(newValue, this.start_date)
      }
    },
    start_date: function (newValue) {
      if (this.date_range > 0 && this.date_range < MAX_NUMBER_OF_DAYS) {
        this.calculateEnd(this.date_range, newValue)
      }
    }
  },
  emits: ['calendarEntryModalSaved']
})
</script>

<style scoped>

</style>
