<template>
  <component :is="tag" :class="className">
    <slot></slot>
    <button
      class="btn btn-close"
      data-bs-dismiss="modal"
      :aria-label="$t('text.abort')"
      :disabled="disabled"
      @click="emitClosed"
    />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalHeader',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    className: {
      type: String,
      default: 'modal-header d-flex align-items-center'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitClosed () {
      this.$emit('modalClosed')
    }
  },
  emits: ['modalClosed']
})
</script>

<style scoped>
</style>
