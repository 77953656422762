<template>
  <div class="g-grid-container">
    <div
      v-for="{ label, value, date, width } in timeaxisUnits.lowerUnits"
      :key="label"
      class="g-grid-line"
      :class="getGridLineClass(date)"
      :style="{
        width,
        background: highlightedUnits?.includes(Number(value)) ? colors.hoverHighlight : undefined
      }"
    />
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
import provideConfig from "../provider/provideConfig"
import useTimeaxisUnits from "../composables/useTimeaxisUnits"
import dayjs from "dayjs"
import { isWeekday } from '@/ganttastic/composables/useDayjsHelper'

// eslint-disable-next-line no-undef
defineProps<{
  highlightedUnits?: number[]
}>()

const { colors } = toRefs(provideConfig())
const { timeaxisUnits } = toRefs(useTimeaxisUnits())
const gridWeekendBg = colors.value.weekendSoft

const getGridLineClass = (date: string|Date) => {
  if (!isWeekday(dayjs(date))) return "g-grid-line-weekend"
}

</script>

<style>
.g-grid-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.g-grid-line {
  width: 1px;
  height: 100%;
  border-left: 1px solid #eaeaea;
}

.g-grid-line-weekend {
  background: v-bind("gridWeekendBg");
}

</style>
