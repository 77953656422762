<template>
  <MainCard>
    <h1 class="h2 text-center">
      {{ $t('auth.login_title') }}
    </h1>
    <Alerts/>
    <Form @submit="signIn" :validation-schema="schema">
      <FormInput type="text" :label="$t('auth.login_username')" name="username" ></FormInput>
      <FormInput type="password" :label="$t('auth.login_password')" name="password" ></FormInput>
<!--      <div class="mb-3 text-end">-->
<!--        <router-link to="/auth/reset" class="fs-5">-->
<!--          {{ $t('auth.login_forgot_password') }}-->
<!--        </router-link>-->
<!--      </div>-->
      <div class="d-grid">
        <Button btn-style="btn-primary" btn-type="submit">
          {{ $t('auth.login_title') }}
        </Button>
      </div>
    </Form>
    <template v-if="$t('auth.login_base_text')">
      <hr/>
      <p class="card-text fs-5 text-center" v-if="$t('auth.login_base_text')">
        {{ $t('auth.login_base_text') }}
      </p>
    </template>
  </MainCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Form } from 'vee-validate'
import * as yup from 'yup'
import http from '@/http-common'
import FormInput from '@/components/forms/FormInput.vue'
import Button from '@/components/base/Button.vue'
import MainCard from '@/components/base/MainCard.vue'
import Alerts from '@/components/base/Alerts.vue'
import { AlertType } from '@/types/internal'
// import { captureException, clearAuthenticationInformation, errorHelper, isValidNext } from '@/utils'
import { AxiosResponse } from 'axios'
import { store } from '@/store'
import { errorHelper, isValidNext } from '@/utils'
export default defineComponent({
  name: 'UserLogin',
  components: {
    Alerts,
    FormInput,
    Button,
    MainCard,
    Form
  },
  data: function () {
    const schema = yup.object().shape({
      username: yup.string()
        .required(this.$t('form_validation.required')),
      password: yup.string()
        .required(this.$t('form_validation.required'))
    })
    let nextUrl = ''

    return {
      schema,
      username: '',
      password: '',
      nextUrl
    }
  },
  methods: {
    signIn (values: Record<string, string>) {
      http.post('auth/token/login/', values).then(
        (response) => {
          localStorage.setItem('authToken', response.data.auth_token)
          localStorage.setItem('username', this.username)
          this.$router.push('/board')
        }, (errorHelper(this.$store)))
        .catch((response) => {
          this.$store.commit('addImmediateAlert', { msg: response.message, type: AlertType.Danger })
        })
    },
  }
})

</script>


<style scoped>

</style>
