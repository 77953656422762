import { ActionContext } from 'vuex'
import { State } from '@/store/state'
import http from '@/http-common'
import {
  CalendarEntry,
  CalendarEntryPayload,
  Employee,
  Project, ProjectPayload
} from '@/types/descript_kundenverwaltung_rest'

export const actions = {
  async getEmployees (context: ActionContext<State, State>): Promise<Employee[]> {
    const response = await http.get('admin/kundenverwaltung/timetrackingentry/rest_api/employee/')
    const employees :Array<Employee> = []
    for (const restEmployee of response.data){
      employees.push(new Employee(restEmployee))
    }
    context.commit('setEmployees', employees)
    return employees
  },
  async getCalendarEntries (context: ActionContext<State, State>): Promise<CalendarEntry[]> {
    const response = await http.get('kundenverwaltung/calendarentry/')
    const calendarEntries :Array<CalendarEntry> = []
    for (const restCalendarEntry of response.data){
      calendarEntries.push(new CalendarEntry(restCalendarEntry))
    }
    context.commit('setCalendarEntries', calendarEntries)
    return calendarEntries
  },
  async createCalendarEntry (context: ActionContext<State, State>, payload: CalendarEntryPayload): Promise<CalendarEntry> {
    const response = await http.post('kundenverwaltung/calendarentry/', JSON.stringify(payload, null, 2))
    return response.data
  },
  async updateCalendarEntry (context: ActionContext<State, State>, payload: CalendarEntryPayload): Promise<CalendarEntry> {
    const response = await http.patch(`kundenverwaltung/calendarentry/${payload.id}/`, JSON.stringify(payload, null, 2))
    context.commit('updateCalenderEntry', new CalendarEntry(response.data))
    return response.data
  },
  async getProjects (context: ActionContext<State, State>): Promise<Project[]> {
    const response = await http.get('admin/kundenverwaltung/timetrackingentry/rest_api/projects/')
    const projects :Array<Project> = []
    for (const restProject of response.data){
      projects.push(new Project(restProject))
    }
    context.commit('setProjects', projects)
    return projects
  },
  async createProject (context: ActionContext<State, State>, payload: ProjectPayload): Promise<Project> {
    const response = await http.post('admin/kundenverwaltung/timetrackingentry/rest_api/projects/', JSON.stringify(payload, null, 2))
    return response.data
  },
  async updateProject (context: ActionContext<State, State>, payload: ProjectPayload): Promise<Project> {
    const response = await http.patch(`admin/kundenverwaltung/timetrackingentry/rest_api/projects/${payload.id}/`, JSON.stringify(payload, null, 2))
    context.commit('updateProject', new Project(response.data))
    return response.data
  },
  async getEndDate (context: ActionContext<State, State>, payload: Record<any, any>): Promise<{end_date: string}> {
    const response = await http.post('kundenverwaltung/end-date/', JSON.stringify(payload, null, 2))
    return response.data
  },
  async getDateRange (context: ActionContext<State, State>, payload: Record<any, any>) {
    const response = await http.post('kundenverwaltung/date-range/', JSON.stringify(payload, null, 2))
    return response.data
  },
  async deleteCalendarEntry (context: ActionContext<State, State>, entryId: number) {
    const response = await http.delete(`kundenverwaltung/calendarentry/${entryId}/`)
    return response.data
  }
}
