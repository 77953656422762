<template>
  <div
    v-for="alert in currentAlerts"
    :id="'alert-' + alert.id.toString()"
    :key="alert.message.msg + alert.message.type + activeLocale"
    class="alert alert-dismissible"
    :class="'alert-' + alert.message.type"
    role="alert"
  >
    {{ $t(alert.message.msg) }}
    <button class="btn btn-close" v-on:click="removeAlert(alert)"></button>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue'
import { Message } from '@/types/internal'

interface AlertWithId {
  id: number,
  message: Message
}

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Alerts',
  data: function () {
    return {
      currentAlerts: [] as Array<AlertWithId>,
      unsubscribeMutationWatcher: null as (() => void) | null,
      nextAlertId: 0
    }
  },
  computed: {
    activeLocale (): string {
      return this.$i18n.locale
    }
  },
  methods: {
    timeOutFunction: function (alert: AlertWithId) {
      window.setTimeout(() => {
        this.removeAlert(alert)
      }, 5500)
    },
    getAlreadyShownAlert: function (messageToCheck: Message): AlertWithId | null {
      for (const shownAlert of this.currentAlerts) {
        if (shownAlert.message.msg === messageToCheck.msg && shownAlert.message.type === messageToCheck.type) {
          return shownAlert
        }
      }

      return null
    },
    pushAlert: function (message: Message) {
      const alreadyShownAlert = this.getAlreadyShownAlert(message)

      if (alreadyShownAlert) {
        const alertElement = document.getElementById('alert-' + alreadyShownAlert.id)
        if (alertElement) {
          alertElement.scrollIntoView({ behavior: 'smooth' })
        }
      } else {
        const alertWithId = {
          id: this.nextAlertId,
          message: message
        }
        this.currentAlerts.push(alertWithId)
        this.nextAlertId = this.nextAlertId + 1

        if (message.autoHide) {
          this.timeOutFunction(alertWithId)
        }

        nextTick().then(
          () => {
            const alertElement = document.getElementById('alert-' + alertWithId.id)
            if (alertElement) {
              alertElement.scrollIntoView({ behavior: 'smooth' })
            }
          }
        )
      }
    },
    removeAlert: function (alert: AlertWithId) {
      const index = this.currentAlerts.indexOf(alert)
      if (index > -1) {
        this.currentAlerts.splice(index, 1)
      }
    }
  },
  created: function () {
    // Iterate the pending alerts of the global store to show
    // any message a former view had created which is then shown
    // on the new view which the other redirected to.
    for (let i = 0; i < this.$store.state.pendingAlerts.length; i++) {
      this.pushAlert(this.$store.state.pendingAlerts[i])
    }

    // When the pending alerts have been grabbed then clear the
    // global store so that the alerts are not repeated over and
    // over again.
    this.$store.commit('clearPendingAlerts')

    this.unsubscribeMutationWatcher = this.$store.subscribe((mutation) => {
      if (mutation.type === 'addImmediateAlert') {
        for (let i = 0; i < this.$store.state.immediateAlerts.length; i++) {
          this.pushAlert(this.$store.state.immediateAlerts[i])
        }

        // Clear global store
        this.$store.commit('clearImmediateAlerts')
      } else if (mutation.type === 'clearAndDismissImmediateAlerts') {
        this.currentAlerts.splice(0, this.currentAlerts.length)
      }
    })
  },
  beforeUnmount: function () {
    if (this.unsubscribeMutationWatcher !== null) {
      this.unsubscribeMutationWatcher()
    }
  }
})
</script>

<style scoped>
</style>
