<template>
  <div
    class="mb-3"
    :class="label ? 'form-floating': ''"
  >
    <select
      :value="inputValue"
      v-model="inputValue"
      @input="handleChange"
      class="form-select"
      :id="name"
      :multiple="isMultiple"
      :class="errorMessage ? ' is-invalid' : ''"
      :size="isMultiple ? 5 : 1"
      :aria-label="label"
    >
      <option v-if="!isMultiple && emptyValue !== null" :value="emptyValue" key="empty" >{{ $t('form_validation.empty_select') }}</option>
      <option v-for="options in options" :key="options.id" :value="options.id" >
        {{ options.label }}
      </option>
    </select>
    <label v-if="label" v-bind:for="name" v-bind:class=labelStyle>{{ label }}</label>
    <div class="invalid-feedback" v-show="!!errorMessage">
      {{ errorMessage }}
    </div>
    <div v-if="isMultiple" class="text-muted fs-5 mt-1">
      {{ $t('base.form_select_multiple_help_text') }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useField } from 'vee-validate'

export interface Option {
  id: number | string,
  label: string
}

export default defineComponent({
  props: {
    label: String,
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array as PropType<Array<Option>>,
      required: true
    },
    value: Array as PropType<Array<number>>,
    emptyValue: {
      required: false,
      default: null
    },
    labelStyle: String,
    isMultiple: {
      required: false,
      default: false
    }
  },
  setup (props) {
    const {
      value: inputValue,
      errorMessage,
      handleChange,
      meta
    } = useField(props.name, undefined, {
      initialValue: props.value
    })

    return {
      inputValue,
      errorMessage,
      handleChange,
      meta
    }
  }
})
</script>

<style scoped>
</style>
