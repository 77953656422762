<template>
  <div class="d-block">
    <h2 class="modal-title">
      {{ title }}
    </h2>
    <p v-if="subTitle" class="modal-title">
      {{ subTitle }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      default: ''
    }
  }
})
</script>

<style scoped>
</style>
