import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentAlerts, (alert) => {
    return (_openBlock(), _createElementBlock("div", {
      id: 'alert-' + alert.id.toString(),
      key: alert.message.msg + alert.message.type + _ctx.activeLocale,
      class: _normalizeClass(["alert alert-dismissible", 'alert-' + alert.message.type]),
      role: "alert"
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t(alert.message.msg)) + " ", 1),
      _createElementVNode("button", {
        class: "btn btn-close",
        onClick: ($event: any) => (_ctx.removeAlert(alert))
      }, null, 8, _hoisted_2)
    ], 10, _hoisted_1))
  }), 128))
}