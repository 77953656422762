import { Store } from 'vuex'
import { getEmptyState, State } from '@/store/state'
import router from '@/router'
import { AlertType } from '@/types/internal'
import * as Sentry from '@sentry/browser'
import { axiosConfiguration } from '@/config/config'
import { AxiosError } from 'axios'
import { isInstanceOf } from '@sentry/utils'

export function isAuthenticated (): boolean {
  return localStorage.getItem('authToken') !== null
}

export function handleLogout (store: Store<State>): void {
  localStorage.removeItem('authToken')
  localStorage.removeItem('username')
  store.replaceState(getEmptyState())
}


/**
 * General Error helper. This function will handle basic error Server responses like being not authorized or 500.
 * For non basic errors a callback is invoked when defined. This way error handling can happen in the components a
 * request is made or store actions are dispatched.
 * @example
 * store.dispatch('...').then(undefined, // success handling is optional
 *   errorHelper((error) => {
 *       // error handling here
 *    }
 *  ))
 * http.post('...').then(
          () => {
            // success
          }, errorHelper((error) => {
            // error handling of errors which weren't caught by the helper
          }
          )).catch(
          (response) => {
            // catching of js-errors
          }
        )
 */
export function errorHelper (store: Store<State>, callback?: (error: any) => void, statusCodesToPassThrough?: Array<number>) {
  return (error: any) => {
    if (error.message === 'Network Error') {
      store.commit('addImmediateAlert', {
        msg: 'error.network_error',
        type: AlertType.Danger
      })
      return
    }

    if (error.response && !statusCodesToPassThrough?.includes(error.response.status)) {
      if (error.response.status === 401) {
        handleLogout(store)
        router.push('/auth')
        store.commit('addPendingAlert', {
          msg: 'error.not_authenticated',
          type: AlertType.Danger
        })
        return
      }
      if (error.response.status === 403) {
        store.commit('addPendingAlert', {
          msg: 'error.permission_denied',
          type: AlertType.Danger
        })
        return
      }
      if (error.response.status === 500) {
        store.commit('addImmediateAlert', {
          msg: 'error.server_error',
          type: AlertType.Danger
        })
        return
      }
    }

    let nonFieldErrorsHandled = false

    if (error.response && error.response.data && 'non_field_errors' in error.response.data) {
      for (const nonFieldError of error.response.data.non_field_errors) {
        store.commit('addImmediateAlert', {
          msg: nonFieldError.toString(), type: AlertType.Danger
        })

        nonFieldErrorsHandled = true
      }
    }
    // We explicitly capture non field errors here to track them in Sentry and also give user feedback below.
    // This is necessary because most store action calls use this error helper and since it handles these errors we
    // would not see them in Sentry otherwise.
    if (!nonFieldErrorsHandled) {
      captureException(error)
    }
    // This is no ordinary error. Handle in origin, when callback is present.
    if (callback) {
      callback(error)
    } else if (!nonFieldErrorsHandled) {
      if (error.response && error.response.data && error.response.data.message) {
        store.commit('addImmediateAlert', {
          msg: error.response.data.message,
          type: AlertType.Danger
        })
      } else if (error.message) {
        store.commit('addImmediateAlert', { msg: error.message, type: AlertType.Danger })
      } else {
        store.commit('addImmediateAlert', { msg: error.toString(), type: AlertType.Danger })
      }
    }
  }
}

export function captureException (event: ErrorEvent | PromiseRejectionEvent | AxiosError | unknown): void {
  console.log(event)
  Sentry.setExtra('username', localStorage.getItem('username'))
  if (event instanceof AxiosError) {
    if (event.response && 'data' in event.response) {
      Sentry.setExtra('response_data', event.response.data)
    }
  }
  Sentry.captureException(event)
}


export function isValidNext (next: string): boolean {
  return next.startsWith(axiosConfiguration.baseURL) || next.startsWith('/')
}

export function convertToTimezone (date: Date, timeZone: string): Date {
  // Based on https://stackoverflow.com/a/54127122 and it may fail on
  // older browsers which do not support parameters for toLocaleString
  // method. Unfortunately there is no available standard library
  // method to do the job directly and therefore this workaround by
  // utilizing toLocaleString is used.
  return new Date(date.toLocaleString('en-US', { timeZone }))
}

export function setProperStartAndEndDateInTimezone(values: Record<string, any>) {
  const browserTimeZone = (new window.Intl.DateTimeFormat()).resolvedOptions().timeZone
  const offset = new Date().getTimezoneOffset()
  // set start date in TZ
  values['start_date'] = new Date(new Date(values['start_date']).getTime() + offset * 60 * 1000)
  values['start_date'] = convertToTimezone(new Date(values['start_date']), browserTimeZone)
  // set end date in TZ by adding 1 Day and subtracting one second and setting it to TZ
  values['end_date'] = new Date(values['end_date'])
  values['end_date'].setDate(values['end_date'].getDate() + 1)
  values['end_date'] = new Date(new Date(values['end_date']).getTime() + offset * 60 * 1000)
  values['end_date'] = new Date(values['end_date'] - 1000)
  values['end_date'] = convertToTimezone(new Date(values['end_date']), browserTimeZone)
  return values
}
